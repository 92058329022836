import {
  Badge,
  Box,
  Button,
  Flex,
  FlexProps,
  Heading,
  HStack,
  List,
  ListItem,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfoTip } from '@gamma-app/ui'
import { Trans } from '@lingui/macro'
import NextLink from 'next/link'
import React, { memo, useMemo } from 'react'

import { ProductPrice } from 'modules/api'
import { useAllFeatureFlags } from 'modules/featureFlags/hooks/useFeatureFlag'
import { useLocalizedFunction } from 'modules/i18n/hooks/useLocalizedFunction'
import { formatCurrency } from 'modules/i18n/utils/currency'
import {
  getCurrencyDivisor,
  getPriceDisplayAnnually,
  getPriceDisplayMonthly,
  shouldShowCurrencyDisplayAsCode,
} from 'modules/monetization/utils'
import { useUserContext } from 'modules/user'
import { useSSRMounted } from 'utils/hooks'

import type {
  BillingCycleKey,
  ProductDetailFeature,
  ProductKey,
} from '../../types'
import { getProductDetails } from './constants'
import { sparkleImageDataURI } from './sparkleImageDataURI'

const Feature = ({
  feature,
  type,
}: {
  feature: ProductDetailFeature
  type: 'feature' | 'include'
}) => {
  return (
    <ListItem
      display="flex"
      alignItems="center"
      fontWeight="medium"
      fontSize="sm"
    >
      <HStack alignItems="center" spacing={2} justifyContent="center">
        {type === 'feature' ? (
          <Box
            bg="gradient.blue-to-orange"
            as="span"
            display="inline-block"
            sx={{
              width: 4,
              height: 4,
              maskImage: `url(${sparkleImageDataURI})`,
              maskSize: 'contain',
              maskRepeat: 'no-repeat',
            }}
          />
        ) : (
          <FontAwesomeIcon icon={faCheck} color="trueblue.500" />
        )}

        <Text as="span" display="inline-block">
          {feature.label}
          {feature.infoTip && (
            <InfoTip label={feature.infoTip} color="gray.400" ml={2} />
          )}
        </Text>
        {feature.isBeta && (
          <Badge colorScheme="green">
            <Trans>Beta</Trans>
          </Badge>
        )}
      </HStack>
    </ListItem>
  )
}

const PriceAndBillingCycleSkeleton = ({
  displayAsTwoLines,
}: {
  displayAsTwoLines: boolean
}) => {
  return (
    <Stack spacing={2} h="80px">
      <Box minH="12">
        <Stack
          align={displayAsTwoLines ? 'flex-start' : 'flex-end'}
          direction={displayAsTwoLines ? 'column' : 'row'}
        >
          <Skeleton height="3em" width="4.5em" />
          <Stack direction="row">
            <Skeleton height=".75em" width="3em" />
            <Skeleton height=".75em" width="3em" />
          </Stack>
        </Stack>
      </Box>
      <Skeleton height="1.5em" width="10em" />
    </Stack>
  )
}

const BillingCycleDescription = memo(
  ({
    isFree,
    selectedBillingCycleKey,
    productPrice,
    displayAsTwoLines,
  }: {
    isFree: boolean
    selectedBillingCycleKey: BillingCycleKey
    productPrice?: ProductPrice
    displayAsTwoLines: boolean
  }) => {
    const annualPriceDisplay = useMemo(() => {
      return productPrice?.frequencyUnit === 'year'
        ? getPriceDisplayAnnually(productPrice)
        : null
    }, [productPrice])
    return (
      <Text
        textAlign={displayAsTwoLines ? 'left' : undefined}
        color="gray.700"
        fontSize="sm"
      >
        {isFree ? (
          <Trans>Always free, no commitment</Trans>
        ) : selectedBillingCycleKey === 'monthly' ? (
          <Trans>Billed monthly</Trans>
        ) : annualPriceDisplay?.discountedPrice ? (
          <Trans>
            <Text
              as="span"
              textDecoration="line-through"
              mr={1}
              color="gray.400"
            >
              {annualPriceDisplay.price}
            </Text>
            {annualPriceDisplay.discountedPrice} billed annually
          </Trans>
        ) : (
          <Trans>{annualPriceDisplay?.price} billed annually</Trans>
        )}
      </Text>
    )
  }
)

BillingCycleDescription.displayName = 'BillingCycleDescription'

const PriceAndBillingCycle = memo(
  ({
    isFree,
    productPrice,
    selectedBillingCycleKey,
    currencyOverride,
  }: {
    isFree: boolean
    productPrice?: ProductPrice
    selectedBillingCycleKey: BillingCycleKey
    currencyOverride?: ProductPrice['currency']
  }) => {
    const { isUserLoading } = useUserContext()
    const displayAsTwoLines =
      // Break the price display into two lines when:
      // The price has many digits, e.g. 10000
      getCurrencyDivisor(productPrice?.currency) < 100 ||
      // The currency is displayed as a code, e.g. TWD
      shouldShowCurrencyDisplayAsCode(productPrice?.currency)

    const isMounted = useSSRMounted()
    if (!isMounted || isUserLoading || (!isFree && !productPrice)) {
      return (
        <PriceAndBillingCycleSkeleton displayAsTwoLines={displayAsTwoLines} />
      )
    }
    return (
      <Box>
        <Box minH="12">
          <Stack
            align={displayAsTwoLines ? 'flex-start' : 'center'}
            direction={displayAsTwoLines ? 'column' : 'row'}
          >
            <Trans comment="Example: A$16/per seat/per month. The variable represents a currency symbol + value. If `/per seat/per month` doesn't sound natural in your language, feel free to translate this from `per user per month` instead.">
              <Text
                fontSize="2.5rem"
                fontWeight="semibold"
                lineHeight="1"
                color="gray.800"
                fontFamily="p22-mackinac-pro"
              >
                <PricePerSeatPerMonth
                  isFree={isFree}
                  productPrice={productPrice}
                  currencyOverride={currencyOverride}
                />
              </Text>
              <Text
                lineHeight={displayAsTwoLines ? 1 : undefined}
                fontSize="sm"
                pb={displayAsTwoLines ? '1' : undefined}
                fontFamily="p22-mackinac-pro"
              >
                / per seat / per month
              </Text>
            </Trans>
          </Stack>
        </Box>
        <BillingCycleDescription
          isFree={isFree}
          selectedBillingCycleKey={selectedBillingCycleKey}
          productPrice={productPrice}
          displayAsTwoLines={displayAsTwoLines}
        />
      </Box>
    )
  }
)

PriceAndBillingCycle.displayName = 'PriceAndBillingCycle'

const PricePerSeatPerMonth = memo(
  ({
    isFree,
    productPrice,
    currencyOverride,
  }: {
    isFree: boolean
    productPrice?: ProductPrice
    currencyOverride?: ProductPrice['currency']
  }) => {
    const { discountedPrice, price } =
      getPriceDisplayMonthly(productPrice) || {}

    if (isFree) {
      return <>{formatCurrency(0, { currency: currencyOverride })}</>
    }
    if (discountedPrice) {
      return (
        <Stack direction="row" alignItems="center">
          <Text color="gray.400" fontSize="2rem" textDecoration="line-through">
            {price}
          </Text>
          <Text>{discountedPrice}</Text>
        </Stack>
      )
    }
    return <>{price}</>
  }
)

PricePerSeatPerMonth.displayName = 'PricePerSeatPerMonth'

export const ProductDetails = ({
  productKey,
  productPrice,
  isUpgrade = true,
  isCurrentProduct,
  upgradeDisabled,
  onClick,
  isPublic,
  selectedBillingCycleKey,
  badgeLabel,
  isHighlighted,
  flexProps,
  leftSideIsRounded,
  rightSideIsRounded,
  currencyOverride,
}: {
  productKey: ProductKey
  productPrice?: ProductPrice
  isUpgrade?: boolean
  isCurrentProduct?: boolean
  upgradeDisabled?: boolean
  badgeLabel?: JSX.Element | null
  onClick?: () => void
  isPublic?: boolean
  selectedBillingCycleKey: BillingCycleKey
  isHighlighted?: boolean
  flexProps?: FlexProps
  leftSideIsRounded?: boolean
  rightSideIsRounded?: boolean
  // Unfortunately, the free plan doesn't have a ProductPrice or currency on it;
  // To solve for this, we will take the currency from the productPrice of the
  // paid plan and pass it down to the free plan so that we show the correct symbol
  currencyOverride?: ProductPrice['currency']
}) => {
  const { user, isUserLoading } = useUserContext()

  const featureFlags = useAllFeatureFlags()
  const ssrMounted = useSSRMounted()
  const isFree = productKey === 'free'
  const hideUpgradeButton =
    !isPublic && (!onClick || (isFree && !isCurrentProduct))
  const productDetails = useLocalizedFunction(getProductDetails)
  const {
    name,
    description,
    featureHeading,
    features,
    includesHeading,
    includes,
  } = isFree ? productDetails.free : productDetails[productKey]

  const fancyBorderWidth = 5
  const borderRadius = '6.9px' // This is 1.1px less than the lg border radius so we don't get wierd edge artifacts

  return (
    <Flex
      scrollSnapAlign="center"
      flex={1}
      position="relative"
      className="product-details"
    >
      {isHighlighted && (
        <Box
          position="absolute"
          inset={`-${fancyBorderWidth}px`}
          _before={{
            content: '""',
            position: 'absolute',
            inset: 0,
            borderRadius: 'xl',
            border: `${fancyBorderWidth}px solid transparent`,
            background: `linear-gradient(120deg, #3300D9 10%, #9D20C9 56.82%, #DF7A6C 150%) border-box`,
            mask: `linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0)`,
            maskComposite: 'subtract',
            opacity: 1,
          }}
          zIndex={1}
          pointerEvents="none"
        />
      )}

      <Flex
        spacing="0"
        flex={1}
        borderLeftRadius={leftSideIsRounded ? borderRadius : 'none'}
        borderRightRadius={rightSideIsRounded ? borderRadius : 'none'}
        direction="column"
        {...flexProps}
      >
        <Stack px={{ base: 4, md: 5 }} pt={4} spacing={2} position="relative">
          {/* This box forms the background behind product title */}
          <Box
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            bg="gray.100"
            borderBottom="1px solid var(--chakra-colors-gray-200)"
            borderTopLeftRadius={leftSideIsRounded ? borderRadius : 'none'}
            borderTopRightRadius={rightSideIsRounded ? borderRadius : 'none'}
          />
          <Heading
            position="relative"
            fontFamily="p22-mackinac-pro"
            fontWeight="600"
            letterSpacing="none"
            size="xl"
            mt={6}
          >
            {name}
          </Heading>
          <Text
            fontSize="sm"
            position="relative"
            lineHeight="1.3em"
            minHeight="2.6em"
            mb={5}
          >
            {description}
          </Text>
          {badgeLabel && (
            <Badge
              position="absolute"
              top={isHighlighted ? '-2px' : '-1px'}
              left="50%"
              transform="translateX(-50%)"
              bgGradient={
                isHighlighted
                  ? 'linear-gradient(90deg, #410ECD 0%, #5D18CA 100%)'
                  : undefined
              }
              bg={isHighlighted ? undefined : 'gray.200'}
              color={isHighlighted ? 'white' : 'gray.700'}
              px="2"
              py="1.5"
              textTransform="unset"
              letterSpacing="unset"
              borderTopRadius="none"
              borderBottomRadius="md"
              outline="2px solid black"
              outlineOffset="-2px"
              outlineColor={isHighlighted ? 'blackAlpha.400' : 'gray.200'}
            >
              {badgeLabel}
            </Badge>
          )}
        </Stack>

        <Stack
          px={{ base: 4, md: 5 }}
          pt={{ base: 4, md: 6 }}
          pb={{ base: 4, md: 8 }}
          spacing="5"
          bg="whiteAlpha.900"
          h="100%"
          borderBottomLeftRadius={leftSideIsRounded ? borderRadius : 'none'}
          borderBottomRightRadius={rightSideIsRounded ? borderRadius : 'none'}
        >
          <PriceAndBillingCycle
            productPrice={productPrice}
            isFree={isFree}
            selectedBillingCycleKey={selectedBillingCycleKey}
            currencyOverride={currencyOverride}
          />
          {hideUpgradeButton ? (
            <></>
          ) : isPublic ? (
            <Button
              variant={isFree ? 'plain' : 'fancy'}
              colorScheme={isFree ? 'gray' : 'trueblue'}
              size="lg"
              width="100%"
              as={NextLink}
              href={user && !isUserLoading ? '/' : '/signup'}
              textShadow="none"
            >
              <Trans>Get Started</Trans>
            </Button>
          ) : isCurrentProduct ? (
            <Button
              as={Box}
              variant="plain"
              size="lg"
              colorScheme="gray"
              textShadow="none"
              // pointerEvents="none"
              bg="gray.100"
              width="100%"
              isDisabled={true}
              visibility={hideUpgradeButton ? 'hidden' : 'visible'}
            >
              <Trans>Your current plan</Trans>
            </Button>
          ) : (
            <Button
              variant={isHighlighted ? 'solid' : 'plain'}
              size="lg"
              width="100%"
              onClick={onClick}
              visibility={hideUpgradeButton ? 'hidden' : 'visible'}
              isDisabled={upgradeDisabled}
              textShadow="none"
            >
              {isUpgrade ? (
                <Trans>Upgrade to {name}</Trans>
              ) : (
                <Trans>Downgrade to {name}</Trans>
              )}
            </Button>
          )}
          <Stack zIndex="1" spacing="2">
            <Text fontWeight="bold">{featureHeading}</Text>
            <List spacing="2">
              {features
                .filter(
                  ({ flag }) => !flag || (ssrMounted && featureFlags[flag])
                )
                .map((feature, index) => (
                  <Feature key={index} feature={feature} type="feature" />
                ))}
            </List>
            <Text fontWeight="bold" mt={4}>
              {includesHeading}
            </Text>
            <List spacing="2">
              {includes?.map((feature, index) => (
                <Feature key={index} feature={feature} type="include" />
              ))}
            </List>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}
