import { i18n } from '@lingui/core'
import sortBy from 'lodash/sortBy'

import { DEFAULT_GAMMA_LOCALE, SUPPORTED_LOCALE_OPTIONS } from './constants'

export const getSupportedLocaleOptionsSorted = () =>
  sortBy(Object.values(SUPPORTED_LOCALE_OPTIONS), (l) =>
    l.displayNameLocalized(i18n._.bind(i18n))
  ).sort((a, b) => {
    if (a.key === DEFAULT_GAMMA_LOCALE) {
      return -1
    }
    if (b.key === DEFAULT_GAMMA_LOCALE) {
      return 1
    }

    return 0
  })
