import { Box, Button, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { useLingui } from '@lingui/react'

import { useFeatureFlag } from 'modules/featureFlags'

import { LOCALE_ROLLOUT_MAP, SupportedLocaleKey } from '../constants'
import { getSupportedLocaleOptionsSorted } from '../fns'

export type LocaleOption = {
  key: SupportedLocaleKey
  displayName: string
}

type LocalePickerProps = {
  onLocaleSelect: (key: SupportedLocaleKey) => void
  selectedLocale: LocaleOption
}

export const LocalePickerList = ({
  onLocaleSelect,
  selectedLocale,
}: LocalePickerProps) => {
  const canUsePrivateBetaLocales = useFeatureFlag('i18nEnabled')
  useLingui() // Force re-render whenever the lingui locale changes, which happens async upon selecting a language

  return (
    <SimpleGrid
      columns={{ base: 2, md: 6 }}
      spacing={4}
      p={4}
      pb={8}
      overflowY="auto"
    >
      {getSupportedLocaleOptionsSorted()
        .filter(
          (locale) =>
            canUsePrivateBetaLocales ||
            LOCALE_ROLLOUT_MAP[locale.key] !== 'private_beta'
        )
        .map((locale) => {
          return (
            <Box
              key={locale.key}
              alignContent="center"
              as={Button}
              variant="ghost"
              colorScheme="gray"
              onClick={() => onLocaleSelect(locale.key)}
              isActive={locale.key === selectedLocale.key}
            >
              <Stack spacing={0}>
                <Stack direction="row" alignItems="center">
                  <Text
                    fontWeight="600"
                    textAlign="center"
                    w="100%"
                    fontSize="sm"
                  >
                    {locale.displayName}
                  </Text>
                </Stack>
              </Stack>
            </Box>
          )
        })}
    </SimpleGrid>
  )
}
