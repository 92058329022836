import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { faGlobe } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Trans, t } from '@lingui/macro'

import { LocalePickerList } from 'modules/i18n/components/LocalePickerList'
import {
  SUPPORTED_LOCALE_OPTIONS,
  SupportedLocaleKey,
} from 'modules/i18n/constants'

export const LocalePickerButtonAndModal = ({
  onLocaleSelect,
  selectedLocaleKey,
  buttonProps,
  showLabelOnMobile = false,
}: {
  onLocaleSelect: (key: SupportedLocaleKey) => void
  selectedLocaleKey: SupportedLocaleKey
  buttonProps?: ButtonProps
  showLabelOnMobile?: boolean
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isMobileScreenSize = useBreakpointValue({ base: true, md: false })
  return (
    <>
      <Button
        w="fit-content"
        onClick={onOpen}
        display={{ base: showLabelOnMobile ? 'block' : 'none', md: 'block' }}
        leftIcon={
          <Box display="inline-block">
            <FontAwesomeIcon icon={faGlobe} />
          </Box>
        }
        variant="link"
        textDecoration="none !important"
        alignContent="center"
        lineHeight="base"
        size="sm"
        fontWeight={500}
        _hover={{
          textDecoration: 'none',
        }}
        {...buttonProps}
      >
        {SUPPORTED_LOCALE_OPTIONS[selectedLocaleKey].displayName}
      </Button>
      {/* Mobile button */}
      <IconButton
        onClick={onOpen}
        display={{ base: showLabelOnMobile ? 'none' : 'block', md: 'none' }}
        aria-label={t`Change language`}
        icon={<FontAwesomeIcon icon={faGlobe} />}
        variant="ghost"
        alignContent="center"
        size="md"
        fontWeight={500}
        {...buttonProps}
      />
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size="6xl"
          isCentered={false}
          preserveScrollBarGap={false}
          blockScrollOnMount={true}
          scrollBehavior="outside"
        >
          {/* Must use a high z-index for modal overlay on mobile, because of the
          mobile marketing site header. See https://github.com/gamma-app/gamma/blob/bf55dc49af193a64ca0a971df7cff4e1e5292107/packages/client/src/modules/marketing_pages/components/MarketingUI/components/Header/MarketingHeader.tsx#L24 */}
          <ModalOverlay zIndex={{ base: 'tooltip', md: undefined }} />
          <ModalContent
            m={{ base: 4, md: 20 }}
            containerProps={{
              // Must use a high z-index for modal overlay on mobile, because of the
              // mobile marketing site header. See
              // https://github.com/gamma-app/gamma/blob/bf55dc49af193a64ca0a971df7cff4e1e5292107/packages/client/src/modules/marketing_pages/components/MarketingUI/components/Header/MarketingHeader.tsx#L24
              zIndex: { base: 'tooltip', md: undefined },
            }}
          >
            <ModalHeader
              textAlign="center"
              fontSize={{ base: 'xl', md: '3xl' }}
            >
              <Trans>Choose language</Trans>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LocalePickerList
                selectedLocale={SUPPORTED_LOCALE_OPTIONS[selectedLocaleKey]}
                onLocaleSelect={(lkey) => {
                  onLocaleSelect(lkey)
                  onClose()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}
